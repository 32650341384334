import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 9" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 9</h1>
      <p>Jeg kan fly!</p>
      <p>Visste du at jeg kan fly mamma? Jeg kan fly, jeg kan fly, jeg kan fly, jeg kan fly, jeg kan flyyyyy.</p>
      <p>Og nå kan du også fly!</p>
      <p>Takk for en livlig natt! Jeg ble så gira at jeg tisset på meg selv i overkant mange ganger. Det rant faktisk over, tihihihihihii!</p>
      <p>Ønsker deg en fly dag!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke
